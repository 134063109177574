import axios from 'axios'
import {
    API_EXTERNAL_URL,
    API_MOVIE_PROGRESS_URL,
    API_MOVIES_DATA_URL,
    API_MOVIES_URL
} from './urls'
import { cleanTitle } from '../utils/cleanMovieTitle'

export const fetchOmdb = async (year: string, title: string) => {
    try {
        const response = await axios.get(`${API_EXTERNAL_URL}/movie`, {
            params: { year, title }
        })

        return response.data
    } catch (error) {
        console.error(`Error fetching poster for ${title}:`, error)
    }
}

export const fetchMoviesDataApi = async (year: string, title: string) => {
    try {
        const response = await axios.get(`${API_MOVIES_DATA_URL}/${title}/${year}`)
        return response.data
    } catch (error: any) {
        if (error.response && error.response.status === 404) {
            console.warn(`Movie not found in local API: ${title} (${year})`)
        } else {
            console.error(`Error fetching data from local API for ${title}:`, error)
        }
        return null
    }
}

export const fetchLatestTimestamp = async () => {
    try {
        const response = await axios.get(`${API_MOVIES_URL}/latest-timestamp`)
        return response.data.latestTimestamp
    } catch (error) {
        console.error('Error fetching latest timestamp:', error)
        return 0
    }
}

export const getStoredOrFetchedMovieData = async (
    title: string,
    year: string,
    type: 'details' | 'poster'
): Promise<{ data: any; key: string } | null> => {
    const cleanedTitle = cleanTitle(title)
    const key = type === 'poster' ? `${cleanedTitle}_${year}_poster` : `${cleanedTitle}_${year}`
    const storedData = localStorage.getItem(key)

    if (storedData) {
        return type === 'poster' ? { data: storedData, key } : { data: JSON.parse(storedData), key }
    }

    // Si no se encuentra en la API local, intentamos obtener desde OMDb
    const omdbData = await fetchOmdb(year, cleanedTitle)
    if (omdbData) {
        const dataToStore = type === 'poster' ? omdbData.Poster : omdbData
        localStorage.setItem(key, type === 'poster' ? dataToStore : JSON.stringify(dataToStore))
        return { data: dataToStore, key }
    }

    return null
}

export const fetchMovieProgress = async (username: string | null, title: string, year: string) => {
    try {
        const response = await axios.get(`${API_MOVIE_PROGRESS_URL}`, {
            params: { username, title, year }
        })
        return response.data.progress
    } catch (error) {
        console.error(`Error fetching movie progress for ${title} (${year}):`, error)
        return 0 // Si falla, devolvemos progreso 0
    }
}

export const saveMovieProgress = async (
    username: string | null,
    title: string,
    year: string,
    progress: number
) => {
    try {
        await axios.post(`${API_MOVIE_PROGRESS_URL}/save`, { username, title, year, progress })
    } catch (error) {
        console.error(`Error saving movie progress for ${title} (${year}):`, error)
    }
}
