import { IconButton, IconButtonProps } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { forwardRef } from 'react'

// Definimos MotionIconButton usando motion y Chakra
const MotionIconButton = motion(
    forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => (
        <IconButton ref={ref} {...props} />
    ))
)

export default MotionIconButton
