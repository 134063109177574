import { Tooltip, VStack, HStack, Text, Icon, Box, Badge } from '@chakra-ui/react'
import { FaInfoCircle } from 'react-icons/fa'
import { MdArrowForward, MdArrowBack, MdOutlineSpaceBar } from 'react-icons/md'

const KeyDetailsTooltip = () => {
    return (
        <Box
            position="absolute"
            top="5px"
            left="10px"
            zIndex="2"
            justifyContent="center"
            alignItems="center"
            display={{ base: 'none', md: 'flex' }}
        >
            <Tooltip
                label={
                    <VStack align="start" spacing={2}>
                        <HStack>
                            <Badge>
                                <Icon as={MdOutlineSpaceBar} />
                            </Badge>
                            <Text fontSize="sm">Reproducir/Pausar</Text>
                        </HStack>
                        <HStack>
                            <Badge>
                                <Icon as={MdArrowForward} />
                            </Badge>
                            <Text fontSize="sm">Adelantar 10 segundos</Text>
                        </HStack>
                        <HStack>
                            <Badge>
                                <Icon as={MdArrowBack} />
                            </Badge>
                            <Text fontSize="sm">Retroceder 10 segundos</Text>
                        </HStack>
                        <HStack>
                            <Text fontSize="sm">
                                <Badge>M </Badge> Silenciar/Activar sonido
                            </Text>
                        </HStack>
                        <HStack>
                            <Text fontSize="sm">
                                <Badge>F </Badge> Pantalla completa
                            </Text>
                        </HStack>
                    </VStack>
                }
                fontSize="md"
                bg="gray.700"
                color="white"
                placement="top"
                p={4}
                borderRadius="md"
            >
                <span>
                    <Icon as={FaInfoCircle} color="white" boxSize={5} cursor="pointer" />
                </span>
            </Tooltip>
        </Box>
    )
}

export default KeyDetailsTooltip
