import { HStack, IconButton } from '@chakra-ui/react'
import { FaClosedCaptioning, FaExpand } from 'react-icons/fa'

interface AditionalControlsProps {
    areSubtitlesVisible: boolean
    handleFullScreen: () => void
    subtitleUrl: string | null
    toggleSubtitles: () => void
}

const AditionalControls: React.FC<AditionalControlsProps> = ({
    areSubtitlesVisible,
    handleFullScreen,
    subtitleUrl,
    toggleSubtitles
}) => (
    <HStack spacing={2} ml="auto">
        {subtitleUrl && (
            <IconButton
                aria-label="Toggle Subtitles"
                icon={<FaClosedCaptioning />}
                onClick={toggleSubtitles}
                color={areSubtitlesVisible ? 'red.500' : 'white'}
                variant="ghost"
                fontSize="20px"
            />
        )}
        <IconButton
            aria-label="Fullscreen"
            icon={<FaExpand />}
            onClick={() => {
                handleFullScreen()
                if (
                    typeof window !== 'undefined' &&
                    window.screen.orientation &&
                    (window.screen.orientation as any).lock
                ) {
                    ;(window.screen.orientation as any).lock('landscape').catch(() => {})
                }
            }}
            color="white"
            variant="ghost"
            fontSize="20px"
        />
    </HStack>
)

export default AditionalControls
