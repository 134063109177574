import { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { toBase64 } from '../utils/toBase64'

interface UseSeriesPlayerProps {
    subtitleText?: string | null
}

const useSeriesPlayer = ({ subtitleText }: UseSeriesPlayerProps) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const hideControlsTimeout = useRef<NodeJS.Timeout | null>(null)
    const videoRef = useRef<ReactPlayer>(null)

    const [areSubtitlesVisible, setAreSubtitlesVisible] = useState(true)
    const [duration, setDuration] = useState(0)
    const [hoverTime, setHoverTime] = useState<number | null>(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isMuted, setIsMuted] = useState(true)
    const [isPlaying, setIsPlaying] = useState(true)
    const [progress, setProgress] = useState(0)
    const [showControls, setShowControls] = useState(true)
    const [volume, setVolume] = useState(0.4)

    const savedProgress = useRef(0)
    const savedPlayingState = useRef(true)

    const subtitleUrl = subtitleText ? `data:text/vtt;base64,${toBase64(subtitleText)}` : null

    const currentTime = progress * duration
    const remainingTime = duration - currentTime

    useEffect(() => {
        const handleResize = () => {
            if (videoRef.current) {
                savedProgress.current = videoRef.current.getCurrentTime()
                savedPlayingState.current = isPlaying
                setIsPlaying(false)
            }
        }

        const handleResizeEnd = () => {
            if (videoRef.current) {
                videoRef.current.seekTo(savedProgress.current)
                setIsPlaying(savedPlayingState.current)
            }
        }

        window.addEventListener('resize', handleResize)
        window.addEventListener('resize', handleResizeEnd)

        return () => {
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('resize', handleResizeEnd)
        }
    }, [isPlaying])

    useEffect(() => {
        const timeoutId = setTimeout(() => setShowControls(false), 3000)
        return () => clearTimeout(timeoutId)
    }, [showControls])

    useEffect(() => {
        // Set initial timeout to hide controls
        resetHideControlsTimeout()
        return () => {
            // Cleanup timeout on unmount
            if (hideControlsTimeout.current) {
                clearTimeout(hideControlsTimeout.current)
            }
        }
    }, [])

    const resetHideControlsTimeout = () => {
        // Clear any existing timeout
        if (hideControlsTimeout.current) {
            clearTimeout(hideControlsTimeout.current)
        }
        // Set a new timeout to hide controls after 3 seconds of inactivity
        hideControlsTimeout.current = setTimeout(() => {
            setShowControls(false)
        }, 3000)
    }

    const handleMouseMove = () => {
        setShowControls(true)
        resetHideControlsTimeout()
    }

    const handlePlayPause = () => {
        setIsPlaying(!isPlaying)
    }

    const handleDuration = (videoDuration: number) => {
        setDuration(videoDuration)
    }

    const handleFullscreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen()
        } else if (containerRef.current) {
            containerRef.current.requestFullscreen()
        }
    }

    const handleProgress = (state: { played: number }) => {
        setProgress(state.played)
    }

    const handleSeekChange = (value: number) => {
        if (videoRef.current) {
            videoRef.current.seekTo(value)
            setProgress(value)
        }
    }

    const handleSliderHover = (event: React.MouseEvent<HTMLDivElement>, sliderWidth: number) => {
        const hoverPosition = event.clientX - event.currentTarget.getBoundingClientRect().left
        const hoverPercentage = hoverPosition / sliderWidth
        setHoverTime(hoverPercentage * duration)
    }

    const handleVolumeChange = (value: number) => {
        setVolume(value)
        setIsMuted(value === 0)
    }

    const clearHoverTime = () => {
        setHoverTime(null)
    }

    const skipForward = (seconds: number) => {
        if (videoRef.current) {
            const currentTime = videoRef.current.getCurrentTime()
            videoRef.current.seekTo(currentTime + seconds)
        }
    }

    const skipBackward = (seconds: number) => {
        if (videoRef.current) {
            const currentTime = videoRef.current.getCurrentTime()
            videoRef.current.seekTo(currentTime - seconds)
        }
    }

    const toggleMute = () => {
        setIsMuted(!isMuted)
    }

    const toggleSubtitles = () => {
        setAreSubtitlesVisible(!areSubtitlesVisible)
    }

    return {
        areSubtitlesVisible,
        clearHoverTime,
        containerRef,
        duration,
        handleDuration,
        handleFullscreen,
        handleMouseMove,
        handlePlayPause,
        handleProgress,
        handleSeekChange,
        handleSliderHover,
        handleVolumeChange,
        hoverTime,
        isLoading,
        isMuted,
        isPlaying,
        progress,
        remainingTime,
        setIsLoading,
        setIsPlaying,
        showControls,
        skipBackward,
        skipForward,
        subtitleUrl,
        toggleMute,
        toggleSubtitles,
        videoRef,
        volume
    }
}

export default useSeriesPlayer
