import { extendTheme, ThemeConfig } from '@chakra-ui/react'

const config: ThemeConfig = {
    initialColorMode: 'dark',
    useSystemColorMode: false
}

const theme = extendTheme({
    config,
    styles: {
        global: {
            body: {
                bg: 'gray.900',
                color: 'whiteAlpha.900'
            },
            /* Scrollbar style */
            '::-webkit-scrollbar': {
                width: '0px'
            },
            '::-webkit-scrollbar-track': {
                background: '#2D3748'
            },
            '::-webkit-scrollbar-thumb': {
                background: '#4A5568',
                borderRadius: '4px',
                border: '2px solid #2D3748'
            },
            '::-webkit-scrollbar-thumb:hover': {
                background: '#718096'
            }
        }
    },
    colors: {
        brand: {
            900: '#1a202c',
            800: '#2d3748',
            700: '#4a5568'
        }
    },
    fonts: {
        heading: `'Creepster', sans-serif`,
        body: `'Creepster', sans-serif`
    }
})

export default theme
