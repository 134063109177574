import { Flex, Spinner } from '@chakra-ui/react'

const SpinnerCentered = () => (
    <Flex
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        align="center"
        justify="center"
        // bg="rgba(0, 0, 0, 0.7)"
        zIndex={1}
    >
        <Spinner size="xl" color="red.500" />
    </Flex>
)

export default SpinnerCentered
