/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback, useMemo } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import axios from 'axios'
import { Movie } from '../interfaces/movie'
import { cleanTitle } from '../utils/cleanMovieTitle'
import { API_MOVIES_URL } from '../api/urls'
import useFilterStore from '../stores/filterStore'
import useMovieStore from '../stores/movieStore'
import { getStoredOrFetchedMovieData } from '../api/movieService'
import { parseGenres } from '../utils/parseGenres'
import { isEmpty } from '../utils/isObjectEmpty'
import useAuthStore from '../stores/authStore'

const useMovieList = () => {
    const { onOpen, isOpen, onClose } = useDisclosure()

    const [movieDetails, setMovieDetails] = useState<{ [key: string]: any }>({})
    const [currentMovieDetails, setCurrentMovieDetails] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingPlayer, setIsLoadingPlayer] = useState(false)
    const [lastFetchTime, setLastFetchTime] = useState<number | null>(null)

    const movies = useMovieStore(state => state.movies)
    const setMovies = useMovieStore(state => state.setMovies)
    const setMoviePosters = useMovieStore(state => state.setMoviePosters)
    const setSelectedMovie = useMovieStore(state => state.setSelectedMovie)
    const setCurrentVideoUrl = useMovieStore(state => state.setCurrentVideoUrl)
    const setCurrentSubtitleText = useMovieStore(state => state.setCurrentSubtitleText)
    const fetchLatestTimestamp = useMovieStore(state => state.fetchLatestTimestamp)
    const lastUpdated = useMovieStore(state => state.lastUpdated)
    const setIsUpdatingMovies = useMovieStore(state => state.setIsUpdatingMovies)
    const displayedMovies = useMovieStore(state => state.displayedMovies)
    const isModalReady = useMovieStore(state => state.isModalReady)
    const setDisplayedMovies = useMovieStore(state => state.setDisplayedMovies)
    const selectedMovie = useMovieStore(state => state.selectedMovie)
    const setIsModalReady = useMovieStore(state => state.setIsModalReady)
    const currentVideoUrl = useMovieStore(state => state.currentVideoUrl)
    const currentSubtitleText = useMovieStore(state => state.currentSubtitleText)
    const moviePosters = useMovieStore(state => state.moviePosters)
    const isUpdatingMovies = useMovieStore(state => state.isUpdatingMovies)

    const genres = useFilterStore(state => state.genres)
    const selectedGenre = useFilterStore(state => state.selectedGenre)
    const selectedYear = useFilterStore(state => state.selectedYear)
    const setGenres = useFilterStore(state => state.setGenres)
    const setSelectedGenre = useFilterStore(state => state.setSelectedGenre)
    const setSelectedYear = useFilterStore(state => state.setSelectedYear)
    const searchQuery = useFilterStore(state => state.searchQuery)
    const username = useAuthStore(state => state.username)

    const fetchMoviesFromApi = useCallback(async () => {
        setIsLoading(true)
        try {
            const response = await axios.post(API_MOVIES_URL, { username })
            const moviesData = response.data

            const currentTime = Date.now()
            const timeElapsed = lastFetchTime ? currentTime - lastFetchTime : null

            // Solo ejecuta fetchLatestTimestamp si han pasado más de 1 hora (3600000 ms)
            if (timeElapsed === null || timeElapsed > 3600000) {
                const latestTimestamp = await fetchLatestTimestamp()
                setMovies(moviesData, latestTimestamp)
                setLastFetchTime(currentTime)
            } else {
                setMovies(moviesData)
            }
        } catch (error) {
            console.error('Error fetching movies:', error)
        } finally {
            setIsLoading(false)
        }
    }, [lastFetchTime])

    const loadMoviesFromLocalStorage = useCallback(async () => {
        if (isEmpty(movies)) {
            await fetchMoviesFromApi()
        } else {
            setMovies(movies, lastUpdated)
            setIsLoading(false)
        }
    }, [fetchMoviesFromApi])

    const handleOpenPlayer = async (year: string, title: string, file: any, subtitle?: any) => {
        setSelectedMovie({ title, year })
        setIsLoadingPlayer(true)

        const videoUrl = `${API_MOVIES_URL}/stream/${year}/${encodeURIComponent(
            title
        )}/${encodeURIComponent(file.name)}`
        setCurrentVideoUrl(videoUrl)

        const movieDetails = await fetchMovieDetails(title, year, true)

        setCurrentMovieDetails(movieDetails)

        if (subtitle) {
            try {
                const response = await axios.get(
                    `${API_MOVIES_URL}/subtitles/${year}/${encodeURIComponent(
                        title
                    )}/${encodeURIComponent(subtitle.name)}`
                )
                setCurrentSubtitleText(response.data.subtitleText)
            } catch {
                setCurrentSubtitleText(null)
            }
        } else {
            setCurrentSubtitleText(null)
        }

        setIsLoadingPlayer(false)
        onOpen()
    }

    const fetchMoviePoster = useCallback(async (title: string, year: string) => {
        const posterKey = `${cleanTitle(title)}_${year}_poster`
        const cachedPoster = moviePosters[posterKey]

        if (cachedPoster) return

        const result = await getStoredOrFetchedMovieData(title, year, 'poster')
        if (result) {
            const { key: posterKey, data: poster } = result
            const posters = useMovieStore.getState().moviePosters
            setMoviePosters({
                ...posters,
                [posterKey]: poster
            })
        } else {
            console.warn(`Poster not found for movie: ${title} (${year})`)
        }
    }, [])

    const fetchMovieDetails = useCallback(
        async (title: string, year: string, isOpeningPlayer: boolean) => {
            const detailsKey = `${cleanTitle(title)}_${year}_details`
            const cachedDetails = movieDetails[detailsKey]

            if (cachedDetails) return cachedDetails

            try {
                const result = await getStoredOrFetchedMovieData(title, year, 'details')
                if (!result) {
                    console.warn(`Details not found for movie: ${title} (${year})`)
                    return null
                }

                const { data: movieData } = result

                if (!isOpeningPlayer) {
                    setMovieDetails(prevDetails => ({
                        ...prevDetails,
                        [detailsKey]: movieData
                    }))
                }

                const genresArray = parseGenres(movieData.Genre)
                const accumulatedGenres = Array.from(
                    new Set([...useFilterStore.getState().genres, ...genresArray])
                )
                setGenres(accumulatedGenres)

                return movieData
            } catch (error) {
                console.error('Error fetching movie details:', error)
                return null
            }
        },
        [movieDetails, setMovieDetails, setGenres]
    )
    const filteredMovies = useMemo(() => {
        return Object.keys(movies).reduce((acc: any, year) => {
            if (selectedYear && year !== selectedYear) return acc

            const yearMovies = movies[year].filter((movie: any) => {
                const cleanedTitle = cleanTitle(movie.title)
                const movieDetail = movieDetails[`${cleanedTitle}_${year}_details`]
                return selectedGenre
                    ? movieDetail && movieDetail.Genre.includes(selectedGenre)
                    : true
            })

            if (yearMovies.length > 0) acc[year] = yearMovies
            return acc
        }, {})
    }, [selectedYear, selectedGenre, movies, movieDetails])

    useEffect(() => {
        loadMoviesFromLocalStorage()
    }, [])

    useEffect(() => {
        Object.keys(movies).forEach(year => {
            movies[year].forEach((movie: Movie) => {
                fetchMoviePoster(movie.title, year)
                fetchMovieDetails(movie.title, year, false)
            })
        })
    }, [movies])

    useEffect(() => {
        const checkAndUpdateMovies = async () => {
            console.log('Check for movie updates...')
            const serverTimestamp = await fetchLatestTimestamp()
            if (serverTimestamp > lastUpdated) {
                console.log('Updating movies...')
                setIsUpdatingMovies(true)
                await fetchMoviesFromApi()
                setIsUpdatingMovies(false)
            } else {
                setIsLoading(false)
            }
        }

        checkAndUpdateMovies()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        movies: filteredMovies,
        handleOpenPlayer,
        movieDetails,
        genres,
        selectedGenre,
        setSelectedGenre,
        selectedYear,
        setSelectedYear,
        currentMovieDetails,
        isLoading,
        isLoadingPlayer,
        isOpen,
        onClose,
        displayedMovies,
        isModalReady,
        setDisplayedMovies,
        selectedMovie,
        setIsModalReady,
        currentVideoUrl,
        currentSubtitleText,
        moviePosters,
        isUpdatingMovies,
        searchQuery
    }
}

export default useMovieList
