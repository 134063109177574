import axios from 'axios'
import { API_EXTERNAL_URL, API_SERIES_URL } from './urls'

// Function to fetch basic series data
export const fetchSeriesDataApi = async (title: string) => {
    try {
        const response = await axios.get(`${API_EXTERNAL_URL}/movie`, {
            params: {
                title: title
            }
        })
        return response.data
    } catch (error: any) {
        if (error.response && error.response.status === 404) {
            console.warn(`Series not found in external API: ${title}`)
        } else {
            console.error(`Error fetching data from external API for ${title}:`, error)
        }
        return null
    }
}

// Function to fetch basic series data
export const fetchSeasonEpisodesBasicApi = async (title: string, season: string) => {
    try {
        const response = await axios.get(`${API_EXTERNAL_URL}/movie`, {
            params: {
                title,
                season: season.split(' ')[1]
            }
        })
        return response.data.Episodes || []
    } catch (error: any) {
        console.error(`Error fetching season episodes for ${title}, Season ${season}:`, error)
        return []
    }
}

// Function to fetch episode data
export const fetchEpisodeDataApi = async (title: string, season: string, episode: string) => {
    try {
        const response = await axios.get(`${API_EXTERNAL_URL}/movie`, {
            params: {
                title,
                season: season.split(' ')[1],
                episode
            }
        })
        return response.data
    } catch (error: any) {
        console.error(`Error fetching episode data from external API for ${title}:`, error)
        return null
    }
}

// Function to fetch latest-timestamped series data
export const fetchSeriesLatestTimestamp = async () => {
    try {
        const response = await axios.get(`${API_SERIES_URL}/latest-timestamp`)
        return response.data.latestTimestamp
    } catch (error) {
        console.error('Error fetching latest series timestamp:', error)
        return 0
    }
}
