import {
    Box,
    Flex,
    Grid,
    GridItem,
    Heading,
    Image,
    Select,
    Spinner,
    Text,
    VStack
} from '@chakra-ui/react'
import { strings } from '../../../constants/strings'
import { SeriesInfo } from '../../../interfaces/series'
import SeriesVideoPlayer from '../SeriesVideoPlayer'

interface EpisodesSectionProps {
    isMobile: boolean | undefined
    videoUrl: string | null
    subtitleUrl: string | null
    selectedSeries: SeriesInfo | null
    selectedSeason: string
    episodesData: { [key: string]: any[] }
    handleSeasonChange: (season: string) => void
    handleEpisodeSelect: (episode: any) => void
    isLoadingEpisodes: boolean
}

interface EpisodeCardProps {
    episode: any
    index: number
    handleEpisodeSelect: (episode: any) => void
}

const EpisodeCard: React.FC<EpisodeCardProps> = ({ episode, index, handleEpisodeSelect }) => (
    <GridItem
        w="100%"
        h="auto"
        bg="#171923"
        borderRadius="md"
        cursor="pointer"
        onClick={() => handleEpisodeSelect(episode)}
        display="flex"
        flexDirection="column"
        alignItems="center"
        color="white"
        textAlign="center"
        p={2}
        _hover={{ bg: 'gray.600' }}
    >
        <Image
            src={episode.Poster || 'placeholder_image_url'}
            alt="Episode Poster"
            borderRadius="md"
            boxSize="120px"
            objectFit="cover"
        />
        <Text fontSize="sm" textAlign="center" mt={2}>
            {`Cap ${index + 1}: ${episode.Title || 'Sin Título'}`}
        </Text>
    </GridItem>
)

const EpisodeVideoPlayer: React.FC<{ videoUrl: string; subtitleUrl?: string | null }> = ({
    videoUrl,
    subtitleUrl
}) => (
    <Box
        width="100%"
        maxW={{ base: '90vw', lg: '50vw' }}
        height="auto"
        pb={{ base: '0', md: '3rem' }}
    >
        <SeriesVideoPlayer videoUrl={videoUrl} subtitleText={subtitleUrl || undefined} />
    </Box>
)

const EpisodesSection: React.FC<EpisodesSectionProps> = ({
    isMobile,
    videoUrl,
    subtitleUrl,
    selectedSeries,
    selectedSeason,
    episodesData,
    handleSeasonChange,
    handleEpisodeSelect,
    isLoadingEpisodes
}) => (
    <VStack spacing={4} align="stretch" flex="1">
        {isLoadingEpisodes ? (
            <Spinner size="xl" alignSelf="center" color="red.500" />
        ) : !videoUrl ? (
            <>
                <Box>
                    <Text fontSize="sm" color="gray.400">
                        {selectedSeries?.year} • IMDb: {selectedSeries?.imdbRating}
                    </Text>
                    <Text fontSize="md" color="gray.500">
                        {selectedSeries?.plot}
                    </Text>
                </Box>
                <Box mt={4}>
                    <Heading size="md" mb={2}>
                        {strings.selectSeason}
                    </Heading>
                    <Select
                        placeholder="Selecciona una temporada"
                        value={selectedSeason || ''}
                        onChange={e => handleSeasonChange(e.target.value)}
                    >
                        {Object.keys(selectedSeries?.seasons || {}).map(season => (
                            <option key={season} value={season}>
                                {season}
                            </option>
                        ))}
                    </Select>
                    {selectedSeason && episodesData[selectedSeason] && (
                        <Grid
                            templateColumns="repeat(auto-fill, minmax(120px, 1fr))"
                            gap={4}
                            mt={4}
                        >
                            {episodesData[selectedSeason].map((episode: any, index: number) => (
                                <EpisodeCard
                                    key={index}
                                    episode={episode}
                                    index={index}
                                    handleEpisodeSelect={handleEpisodeSelect}
                                />
                            ))}
                        </Grid>
                    )}
                </Box>
            </>
        ) : !isMobile ? (
            <Flex justify="center" width="100%" display={{ base: 'none', lg: 'flex' }}>
                <EpisodeVideoPlayer videoUrl={videoUrl} subtitleUrl={subtitleUrl} />
            </Flex>
        ) : (
            <></>
        )}
    </VStack>
)

export default EpisodesSection
