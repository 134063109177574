import { useEffect } from 'react'
import { Box, Grid, GridItem, Image } from '@chakra-ui/react'
import useSeriesStore from '../../stores/seriesStore'
import SeriesDetailModal from './SeriesDetailModal'
import { SeriesInfo } from '../../interfaces/series'

const SeriesList = () => {
    const {
        loading,
        isUpdatingSeries,
        seriesInfoList,
        lastUpdated,
        fetchSeriesInfo,
        setSeries,
        selectedSeries,
        openModal,
        closeModal,
        checkAndUpdateSeries
    } = useSeriesStore()

    useEffect(() => {
        checkAndUpdateSeries()
    }, [fetchSeriesInfo, lastUpdated, setSeries, checkAndUpdateSeries])

    return (
        <Box p={4}>
            {loading || isUpdatingSeries ? (
                <></>
            ) : (
                <Grid
                    templateColumns={Array.from({ length: 5 }, (_, i) => `repeat(${i + 2}, 1fr)`)}
                    gap={6}
                >
                    {seriesInfoList.map((seriesInfo: SeriesInfo) => (
                        <GridItem
                            key={seriesInfo.title}
                            onClick={() => {
                                closeModal()
                                openModal(seriesInfo)
                            }}
                            _hover={{
                                transform: 'scale(1.05)',
                                cursor: 'pointer'
                            }}
                            transition="transform 0.2s ease, box-shadow 0.2s ease"
                        >
                            <Image src={seriesInfo.poster} borderRadius="10px" />
                        </GridItem>
                    ))}
                </Grid>
            )}
            {selectedSeries && <SeriesDetailModal />}
        </Box>
    )
}

export default SeriesList
