import { Flex, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text } from '@chakra-ui/react'

const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = Math.floor(seconds % 60)
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
}

interface ProgressSliderControlProps {
    progress: number
    handleSeekChange: (value: number) => void
    handleSliderHover: (event: React.MouseEvent<HTMLDivElement>, offsetWidth: number) => void
    clearHoverTime: () => void
    hoverTime: number | null
    duration: number
    remainingTime: number
}

const ProgressSliderControl: React.FC<ProgressSliderControlProps> = ({
    progress,
    handleSeekChange,
    handleSliderHover,
    clearHoverTime,
    hoverTime,
    duration,
    remainingTime
}) => (
    <Flex
        position="absolute"
        bottom="30px"
        left="0"
        right="0"
        px={4}
        align="center"
        justify="space-between"
        zIndex={1}
        maxWidth="100%"
    >
        <Slider
            aria-label="Progress slider"
            min={0}
            max={1}
            step={0.01}
            value={progress}
            onChange={handleSeekChange}
            onMouseMove={e => handleSliderHover(e, e.currentTarget.offsetWidth)}
            onMouseLeave={clearHoverTime}
            flex="1"
            colorScheme="red"
            position="relative"
            maxWidth="99%"
        >
            <SliderTrack bg="gray.600">
                <SliderFilledTrack bg="red.500" />
            </SliderTrack>
            <SliderThumb boxSize={3} />

            {/* Mostrar tiempo de hover */}
            {hoverTime !== null && (
                <Text
                    position="absolute"
                    bottom="35px"
                    left={`${(hoverTime / duration) * 100}%`}
                    transform="translateX(-50%)"
                    fontSize="sm"
                    color="white"
                    bg="rgba(0, 0, 0, 0.7)"
                    px={2}
                    borderRadius="md"
                >
                    {formatTime(hoverTime)}
                </Text>
            )}
        </Slider>

        <Text fontSize="sm" color="white" ml={4}>
            -{formatTime(remainingTime)}
        </Text>
    </Flex>
)

export default ProgressSliderControl
