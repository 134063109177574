import { extendTheme, ThemeConfig } from '@chakra-ui/react'

const config: ThemeConfig = {
    initialColorMode: 'dark',
    useSystemColorMode: false
}

const theme = extendTheme({
    config,
    styles: {
        global: {
            body: {
                bg: 'gray.900',
                color: 'whiteAlpha.900'
            }
        }
    },
    colors: {
        brand: {
            900: '#1a202c',
            800: '#2d3748',
            700: '#4a5568'
        }
    },
    fonts: {
        heading: `'Montserrat', sans-serif`,
        body: `'Montserrat', sans-serif`
    }
})

export default theme
