import { useEffect, useMemo, useState } from 'react'
import {
    Box,
    Grid,
    GridItem,
    Heading,
    Image,
    Text,
    VStack,
    Spinner,
    Center,
    Icon,
    IconButton
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { FaExclamationTriangle } from 'react-icons/fa'
import { TbArrowBigUpLineFilled } from 'react-icons/tb'
import { Movie } from '../../interfaces/movie'
import { cleanTitle } from '../../utils/cleanMovieTitle'
import MovieDetailModal from './MovieDetailModal'
import { genreTranslations } from '../../utils/genreTranslations'
import { strings } from '../../constants/strings'
import SpinnerCentered from '../Common/SpinnerCentered'
import useScrollToTop from '../../hooks/useScrollToTop'

const MovieList: React.FC<{ shuffleTrigger: boolean; movieListData: any }> = ({
    shuffleTrigger,
    movieListData
}) => {
    const {
        currentMovieDetails,
        currentSubtitleText,
        currentVideoUrl,
        displayedMovies,
        handleOpenPlayer,
        isLoading,
        isLoadingPlayer,
        isModalReady,
        isOpen,
        moviePosters,
        movies,
        onClose,
        searchQuery,
        selectedGenre,
        selectedMovie,
        setDisplayedMovies,
        setIsModalReady
    } = movieListData

    const { isVisible, scrollToTop } = useScrollToTop()
    const [isInitializing, setIsInitializing] = useState(true)
    const filteredMovies = useMemo(() => {
        const allMovies = Object.keys(movies)
            .flatMap(year =>
                movies[year].map((movie: Movie) => ({
                    ...movie,
                    year
                }))
            )
            .reverse()

        const filteredMoviesByQuery = allMovies.filter(movie =>
            movie.title.toLowerCase().includes(searchQuery.toLowerCase())
        )

        const sortedMovies = filteredMoviesByQuery.sort((a, b) => {
            const dateA = new Date(a.lastModified)
            const dateB = new Date(b.lastModified)

            // Ordena por lastModified en orden descendente
            if (dateB > dateA) return 1
            if (dateB < dateA) return -1

            // Si lastModified es igual, ordena por año en orden descendente
            const yearA = a.year
            const yearB = b.year
            if (yearB !== yearA) return yearB.localeCompare(yearA)

            // Si año también es igual, ordena por título en orden alfabético
            const titleA = a.title
            const titleB = b.title
            return titleA.localeCompare(titleB)
        })

        return sortedMovies
    }, [movies, searchQuery])

    const shuffleMovies = (movies: Movie[]) => {
        return movies
            .map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)
    }

    useEffect(() => {
        if (shuffleTrigger) {
            setDisplayedMovies(shuffleMovies(filteredMovies))
        } else {
            setDisplayedMovies(filteredMovies)
        }
    }, [shuffleTrigger, setDisplayedMovies, filteredMovies])

    useEffect(() => {
        if (!isLoading && displayedMovies.length > 0) {
            setIsInitializing(false)
        }
    }, [isLoading, displayedMovies])

    return (
        <Box p={[1, 4]} bg="gray.900" minH="100vh" position="relative">
            <Heading
                as="h2"
                size="lg"
                color="white"
                mb={3}
                textAlign="center"
                borderRadius="md"
                p={[1, 0]}
            >
                {selectedGenre && (
                    <>
                        {strings.moviesOf}{' '}
                        <Text as="span" color="red.400">
                            {genreTranslations[selectedGenre] || selectedGenre}
                        </Text>
                    </>
                )}
            </Heading>
            {isInitializing ? (
                <SpinnerCentered />
            ) : filteredMovies.length > 0 ? (
                <Grid
                    templateColumns={[
                        'repeat(2, 1fr)',
                        'repeat(3, 1fr)',
                        'repeat(4 , 1fr)',
                        'repeat(5 , 1fr)',
                        'repeat(6 , 1fr)'
                    ]}
                    gap={{ base: 4, md: 5 }}
                >
                    {displayedMovies.map((movie: any, index: any) => {
                        const fileName = movie.files?.[0]?.name || ''
                        const needsConversion = !fileName.endsWith('.mp4')

                        return (
                            <GridItem
                                as={motion.div}
                                layout
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: 20 }}
                                whileHover={{
                                    scale: 1.05
                                }}
                                transition={{ duration: 0.3, delay: index * 0.05 } as any}
                                key={movie.title}
                                position="relative"
                                onClick={() =>
                                    !needsConversion &&
                                    handleOpenPlayer(
                                        movie.year,
                                        movie.title,
                                        movie.files[0],
                                        movie.subtitles?.[0]
                                    )
                                }
                                cursor={needsConversion ? 'not-allowed' : 'pointer'}
                                textAlign="center"
                                borderRadius={['10px', '20px']}
                            >
                                <VStack align="center">
                                    {isLoadingPlayer &&
                                        selectedMovie?.title === movie.title &&
                                        selectedMovie?.year === movie.year && (
                                            <Center
                                                position="absolute"
                                                top="50%"
                                                left="50%"
                                                transform="translate(-50%, -50%)"
                                                zIndex="1"
                                            >
                                                <Spinner size="lg" color="red.400" />
                                            </Center>
                                        )}

                                    <Image
                                        loading="lazy"
                                        src={
                                            moviePosters[
                                                `${cleanTitle(movie.title)}_${movie.year}_poster`
                                            ] ||
                                            'https://via.placeholder.com/200x300?text=No+Poster'
                                        }
                                        alt={`${movie.title} poster`}
                                        maxHeight={['200px', '260px', '320px']}
                                        width={['170px', '200px', '240px']}
                                        height={['180px', '260px', '320px']}
                                        borderRadius={['10px', '20px']}
                                        objectFit="cover"
                                        opacity={
                                            (isLoadingPlayer &&
                                                selectedMovie?.title === movie.title &&
                                                selectedMovie?.year === movie.year) ||
                                            needsConversion
                                                ? 0.4
                                                : 1
                                        }
                                    />
                                </VStack>
                            </GridItem>
                        )
                    })}
                </Grid>
            ) : (
                <Center flexDirection="column" minH="60vh" color="gray.500">
                    <Icon as={FaExclamationTriangle} boxSize="8" color="red.400" mb={4} />
                    <Text fontSize="lg" fontWeight="bold">
                        No se encontró la película
                    </Text>
                    <Text fontSize="md">Intenta buscar con otro título.</Text>
                </Center>
            )}

            <MovieDetailModal
                isOpen={isOpen && isModalReady}
                onClose={() => {
                    onClose()
                    setIsModalReady(false)
                }}
                videoUrl={currentVideoUrl}
                subtitleText={currentSubtitleText}
                movieDetails={currentMovieDetails}
                onLoaded={() => setIsModalReady(true)}
            />

            {isVisible && (
                <IconButton
                    icon={<Icon as={TbArrowBigUpLineFilled} boxSize="1.5em" />}
                    onClick={scrollToTop}
                    position="fixed"
                    bottom="20px"
                    right="20px"
                    colorScheme="red"
                    aria-label="Scroll to top"
                    borderRadius="full"
                    size="lg"
                    boxShadow="lg"
                />
            )}
        </Box>
    )
}

export default MovieList
