import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    Image,
    Box,
    Button,
    Icon,
    useBreakpointValue
} from '@chakra-ui/react'
import { TiArrowBack } from 'react-icons/ti'
import useSeriesDetail from '../../../hooks/useSeriesDetail'
import { strings } from '../../../constants/strings'
import SeriesHeader from './SeriesHeader'
import VideoSection from './VideoSection'
import EpisodesSection from './EpisodesSection'

const SeriesDetailModal = () => {
    const isMobile = useBreakpointValue({ base: true, lg: false })
    const {
        isModalOpen,
        closeModal,
        selectedSeries,
        selectedSeason,
        videoUrl,
        isLoadingEpisodes,
        episodesData,
        subtitleUrl,
        resetVideo,
        selectedEpisodeTitle,
        selectedSeasonEpisode,
        selectedEpisode,
        handleSeasonChange,
        handleEpisodeSelect
    } = useSeriesDetail()

    return (
        <Modal isOpen={isModalOpen} onClose={closeModal} size="5xl">
            <ModalOverlay />
            <ModalContent
                maxW={{ base: '95%', md: '80%', lg: '75%' }}
                maxH={{ base: '90vh', lg: '85vh' }}
                minH="50vh"
                overflow="hidden"
                borderRadius="lg"
                bg={'#171923'}
            >
                <SeriesHeader
                    title={selectedSeries?.title}
                    episodeTitle={selectedEpisodeTitle}
                    seasonEpisode={selectedSeasonEpisode}
                    episode={selectedEpisode}
                />

                <ModalCloseButton />
                <ModalBody
                    display="flex"
                    flexDirection={{ base: 'column', lg: 'row' }}
                    gap={4}
                    overflowY="auto"
                >
                    {isMobile && videoUrl && (
                        <VideoSection
                            videoUrl={videoUrl}
                            subtitleUrl={subtitleUrl}
                            resetVideo={resetVideo}
                        />
                    )}

                    <Box
                        flexShrink={0}
                        textAlign="center"
                        display={{ base: videoUrl ? 'none' : 'block', lg: 'block' }}
                    >
                        <Image
                            src={selectedSeries?.poster}
                            alt={`${selectedSeries?.title} poster`}
                            borderRadius="md"
                            maxW={{ base: '150px', md: '200px', lg: '300px' }}
                            maxH="300px"
                            objectFit="cover"
                        />
                        {videoUrl && (
                            <Button
                                onClick={resetVideo}
                                colorScheme="gray"
                                mt={3}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                gap={1}
                                width="full"
                            >
                                <Icon as={TiArrowBack} aria-label="Arrow back" fontSize="20px" />
                                {strings.goBackToEpisodes}
                            </Button>
                        )}
                    </Box>

                    <EpisodesSection
                        isMobile={isMobile}
                        videoUrl={videoUrl}
                        subtitleUrl={subtitleUrl}
                        selectedSeries={selectedSeries}
                        selectedSeason={selectedSeason}
                        episodesData={episodesData}
                        handleSeasonChange={handleSeasonChange}
                        handleEpisodeSelect={handleEpisodeSelect}
                        isLoadingEpisodes={isLoadingEpisodes}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default SeriesDetailModal
