import { Box } from '@chakra-ui/react'
import useMoviePlayer from '../../hooks/useMoviePlayer'
import { boxProps } from '../../constants/styles/playerStyles'
import SpinnerCentered from '../Common/SpinnerCentered'
import VideoPlayerControls from '../Common/VidePlayerControls'
import VideoPlayer from '../Common/VidePlayerControls/VideoPlayer'

interface MovieVideoPlayerProps {
    videoUrl: string
    subtitleText?: string | null
}

const MovieVideoPlayer = ({ videoUrl, subtitleText }: MovieVideoPlayerProps) => {
    const {
        videoRef,
        containerRef,
        isPlaying,
        volume,
        duration,
        isMuted,
        progress,
        handleProgress,
        hoverTime,
        handleSeekChange,
        areSubtitlesVisible,
        showControls,
        togglePlayPause,
        handleVolumeChange,
        toggleMute,
        handleFullScreen,
        toggleSubtitles,
        subtitleUrl,
        handleMouseMove,
        skipForward,
        skipBackward,
        handleSliderHover,
        handleDuration,
        clearHoverTime,
        setIsLoading,
        isLoading,
        remainingTime
    } = useMoviePlayer({ subtitleText })

    return (
        <Box ref={containerRef} onMouseMove={handleMouseMove} {...boxProps}>
            {isLoading && <SpinnerCentered />}

            <VideoPlayer
                videoRef={videoRef}
                videoUrl={videoUrl}
                isPlaying={isPlaying}
                isMuted={isMuted}
                volume={volume}
                togglePlayPause={togglePlayPause}
                handleProgress={handleProgress}
                handleDuration={handleDuration}
                areSubtitlesVisible={areSubtitlesVisible}
                subtitleUrl={subtitleUrl}
                setIsLoading={setIsLoading}
            />

            {showControls && !isLoading && (
                <VideoPlayerControls
                    areSubtitlesVisible={areSubtitlesVisible}
                    clearHoverTime={clearHoverTime}
                    duration={duration}
                    handleFullScreen={handleFullScreen}
                    handleSeekChange={handleSeekChange}
                    handleSliderHover={handleSliderHover}
                    handleVolumeChange={handleVolumeChange}
                    hoverTime={hoverTime}
                    isMuted={isMuted}
                    isPlaying={isPlaying}
                    progress={progress}
                    remainingTime={remainingTime}
                    showControls={showControls}
                    skipBackward={skipBackward}
                    skipForward={skipForward}
                    subtitleUrl={subtitleUrl}
                    toggleMute={toggleMute}
                    togglePlayPause={togglePlayPause}
                    toggleSubtitles={toggleSubtitles}
                    volume={volume}
                />
            )}
        </Box>
    )
}

export default MovieVideoPlayer
