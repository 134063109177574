import { useState } from 'react'
import { useToast } from '@chakra-ui/react'
import axios from 'axios'
import { API_USER_URL } from '../api/urls'
import { getUsernameFromToken } from '../utils/getUsernameFromToken'
import useAuthStore from '../stores/authStore'

const useLogin = () => {
    const [showPassword, setShowPassword] = useState(false)
    const toast = useToast()

    const handleLoginSuccess = useAuthStore(state => state.handleLoginSuccess)
    const isLoading = useAuthStore(state => state.isLoading)
    const setLoading = useAuthStore(state => state.setLoading)
    const username = useAuthStore(state => state.username)
    const setUsername = useAuthStore(state => state.setUsername)

    const togglePasswordVisibility = () => {
        setShowPassword(prev => !prev)
    }

    const handleLogin = async (username: string, password: string) => {
        setLoading(true)
        try {
            const response = await axios.post(`${API_USER_URL}/login`, {
                username,
                password
            })

            const { accessToken } = response.data
            const usernameFromToken = getUsernameFromToken(accessToken)

            if (usernameFromToken) {
                localStorage.setItem('authToken', accessToken)
                localStorage.setItem('username', usernameFromToken)
                handleLoginSuccess(accessToken, usernameFromToken)
                toast({
                    title: 'Inicio de sesión exitoso.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true
                })
            } else {
                throw new Error('Invalid token format')
            }
        } catch (error: any) {
            console.error('Error during login:', error)
            toast({
                title: 'Inicio de sesión fallido.',
                description: error.response?.data?.message || 'Unexpected error occurred',
                status: 'error',
                duration: 3000,
                isClosable: true
            })
        } finally {
            setLoading(false)
        }
    }

    return {
        handleLogin,
        togglePasswordVisibility,
        showPassword,
        isLoading,
        username,
        setUsername
    }
}

export default useLogin
