import { useEffect } from 'react'
import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    Text,
    Divider,
    Image,
    Badge,
    HStack,
    VStack,
    Spinner,
    Center,
    Tooltip
} from '@chakra-ui/react'
import MovieVideoPlayer from './MovieVideoPlayer'
import useMovieRating from '../../hooks/useMovieRating'
import StarRating from './StartRating'
import { strings } from '../../constants/strings'
import KeyDetailsTooltip from '../Common/KeyDetailsTooltip'

interface Rating {
    Source: string
    Value: string
}

interface MovieDetailModalProps {
    isOpen: boolean
    onClose: () => void
    onLoaded: () => void
    videoUrl: string | null
    subtitleText: string | null
    movieDetails: {
        Title?: string
        Year?: string
        Genre?: string
        Plot?: string
        Director?: string
        Actors?: string
        Poster?: string
        Runtime?: string
        Ratings?: Rating[]
    } | null
}

const MovieDetailModal = ({
    isOpen,
    onClose,
    onLoaded,
    videoUrl,
    subtitleText,
    movieDetails
}: MovieDetailModalProps) => {
    const { Title, Year, Poster } = movieDetails || {}

    const { averageRating, userRating, rateMovie, loading } = useMovieRating(Title, Year)

    useEffect(() => {
        if (!loading) {
            onLoaded()
        }
    }, [loading, onLoaded])

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
            <ModalOverlay />
            <ModalContent
                bg="gray.900"
                color="white"
                p={4}
                maxW={['95vw', '96vw', '95vw', '80vw']}
                maxH={['95vh', '96vh', '90vh']}
                width={'100%'}
                borderRadius="lg"
                overflow="hidden"
                transition={'all 0.3s'}
            >
                <ModalCloseButton color="white" top="10px" right="10px" zIndex="1" />
                <ModalBody
                    display="flex"
                    flexDirection={['column', 'column', 'row']}
                    p={4}
                    maxHeight="80vh"
                    overflowY="auto"
                    overflowX="hidden"
                    transition={'all 0.3s'}
                    maxH="85vh"
                >
                    {loading ? (
                        <Center w="100%" h="100%">
                            <Spinner size="xl" color="red.400" />
                        </Center>
                    ) : (
                        <>
                            {/* Video Section */}
                            <Box flex="3" mb={[4, 0]} mr={[0, 6]} width="100%" position="relative">
                                {videoUrl && (
                                    <MovieVideoPlayer
                                        videoUrl={videoUrl}
                                        subtitleText={subtitleText}
                                    />
                                )}
                            </Box>

                            {/* Information Section */}
                            <Box flex="2" p={2}>
                                <Badge
                                    size="lg"
                                    mb={4}
                                    fontSize={['lg', '1.3rem']}
                                    textAlign="center"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    whiteSpace="normal"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    style={{
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 2
                                    }}
                                >
                                    {movieDetails?.Title || 'Title Not Available'}
                                    <KeyDetailsTooltip />
                                </Badge>
                                <Divider borderColor="gray.600" mb={4} />

                                <Box display="flex" justifyContent="center" mb={4}>
                                    {movieDetails?.Poster && (
                                        <Image
                                            src={Poster}
                                            alt={`${movieDetails.Title} poster`}
                                            borderRadius="md"
                                            boxSize={['150px', '200px', '250px']}
                                            objectFit="cover"
                                            boxShadow="lg"
                                        />
                                    )}
                                </Box>

                                {/* System to rate movies with stars */}
                                <Box mt={4} textAlign="center">
                                    <Text fontSize="md" mb={2} color="gray.400">
                                        {strings.rateThisMovie}
                                    </Text>
                                    <HStack justifyContent="center">
                                        <Tooltip
                                            label={`Promedio de calificación: ${
                                                !isNaN(averageRating)
                                                    ? averageRating.toFixed(1)
                                                    : '0.0'
                                            }/5`}
                                            fontSize="sm"
                                            bg="gray.300"
                                            color="black"
                                            borderRadius="md"
                                            p={2}
                                        >
                                            <span>
                                                <StarRating
                                                    count={userRating || 0}
                                                    onClick={rateMovie}
                                                />
                                            </span>
                                        </Tooltip>
                                    </HStack>
                                </Box>

                                {/* Información adicional */}
                                <Text fontSize="sm" mb={2}>
                                    <strong style={{ color: 'gray.400' }}>{strings.year}:</strong>{' '}
                                    {movieDetails?.Year}
                                </Text>
                                <Text fontSize="sm" mb={2}>
                                    <strong style={{ color: 'gray.400' }}>{strings.genre}:</strong>{' '}
                                    {movieDetails?.Genre}
                                </Text>
                                <Text fontSize="sm" mb={4}>
                                    <strong style={{ color: 'gray.400' }}>{strings.plot}:</strong>{' '}
                                    {movieDetails?.Plot}
                                </Text>
                                <Text fontSize="sm" mb={2}>
                                    <strong style={{ color: 'gray.400' }}>
                                        {strings.director}:
                                    </strong>{' '}
                                    {movieDetails?.Director}
                                </Text>
                                <Text fontSize="sm" mb={2}>
                                    <strong style={{ color: 'gray.400' }}>{strings.cast}:</strong>{' '}
                                    {movieDetails?.Actors}
                                </Text>

                                {/* Ratings de OMDb */}
                                {movieDetails?.Ratings && movieDetails.Ratings.length > 0 && (
                                    <VStack align="start" spacing={2} mt={4}>
                                        {movieDetails.Ratings.map((rating, index) => {
                                            const [score, maxScore] = rating.Value.includes('/')
                                                ? rating.Value.split('/')
                                                : [rating.Value, null]

                                            return (
                                                <HStack key={index} spacing={3} align="center">
                                                    <Badge
                                                        colorScheme="purple"
                                                        fontSize="xs"
                                                        p={1}
                                                        borderRadius="md"
                                                    >
                                                        {rating.Source}
                                                    </Badge>
                                                    <HStack spacing={1} align="center">
                                                        <Text
                                                            fontSize="lg"
                                                            fontWeight="bold"
                                                            color="yellow.300"
                                                        >
                                                            {score}
                                                        </Text>
                                                        {maxScore && (
                                                            <>
                                                                <Text
                                                                    fontSize="sm"
                                                                    color="gray.400"
                                                                >
                                                                    /
                                                                </Text>
                                                                <Text
                                                                    fontSize="lg"
                                                                    fontWeight="bold"
                                                                    color="yellow.300"
                                                                >
                                                                    {maxScore}
                                                                </Text>
                                                            </>
                                                        )}
                                                    </HStack>
                                                </HStack>
                                            )
                                        })}
                                    </VStack>
                                )}
                            </Box>
                        </>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default MovieDetailModal
