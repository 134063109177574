import { Center, Box, VStack, FormControl, FormLabel, Input, Button, Text } from '@chakra-ui/react'
import ProgressBar from '../Common/ProgressBar'
import useVideoUpload from '../../hooks/useVideoUpload'

const VideoUpload: React.FC = () => {
    const {
        handleFileChange,
        handleUpload,
        uploadProgress,
        conversionProgress,
        isUploading,
        message,
        buttonLabel
    } = useVideoUpload()

    return (
        <Center minH="80vh">
            <Box p={6} boxShadow="lg" borderWidth="1px" borderRadius="lg" bg="gray.900">
                <VStack spacing={6}>
                    <FormControl>
                        <FormLabel textAlign="center" color="white" mb={4}>
                            Sube un video para convertir a mp4
                        </FormLabel>
                        <Input
                            type="file"
                            accept="video/*"
                            onChange={handleFileChange}
                            display="block"
                            mx="auto"
                            maxW="100%"
                            borderColor="gray.600"
                            height="33px"
                        />
                    </FormControl>
                    <Button
                        colorScheme="blue"
                        onClick={handleUpload}
                        isDisabled={
                            isUploading ||
                            (conversionProgress !== null && conversionProgress !== 100)
                        }
                        w="full"
                    >
                        {buttonLabel}
                    </Button>
                    {isUploading && <ProgressBar value={uploadProgress} color="green" />}
                    {conversionProgress !== null && (
                        <ProgressBar value={conversionProgress} color="blue" />
                    )}
                    {message && <Text color="white">{message}</Text>}
                </VStack>
            </Box>
        </Center>
    )
}

export default VideoUpload
