import {
    HStack,
    IconButton,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack
} from '@chakra-ui/react'
import { FaVolumeMute, FaVolumeUp } from 'react-icons/fa'

interface VolumeControlProps {
    handleVolumeChange: (value: number) => void
    isMuted: boolean
    toggleMute: () => void
    volume: number
}

const VolumeControl: React.FC<VolumeControlProps> = ({
    handleVolumeChange,
    isMuted,
    toggleMute,
    volume
}) => (
    <HStack spacing={2}>
        <IconButton
            aria-label="Mute/Unmute"
            icon={isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
            onClick={toggleMute}
            color="white"
            variant="ghost"
            fontSize="20px"
        />
        <Slider
            aria-label="Volume slider"
            min={0}
            max={1}
            step={0.01}
            value={volume}
            onChange={handleVolumeChange}
            width="80px"
            colorScheme="red"
        >
            <SliderTrack bg="gray.600">
                <SliderFilledTrack bg="red.500" />
            </SliderTrack>
            <SliderThumb boxSize={3} />
        </Slider>
    </HStack>
)

export default VolumeControl
